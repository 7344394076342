import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { scroller } from 'react-scroll';
import styles from './AddressInformation.scss';
import {
  SetCurbsideOutfitInfoPayload,
  SetCurbsidePayload,
  SetDineInTablePayload,
  SetDispatchTimePayload,
  SetDispatchTypePayload,
} from '../../../../state/checkout/checkout.actions.types';
import dataHooks from '../../data-hooks';
import DispatchTypeSelector from '../DispatchTypeSelector';
import DispatchTimeSelector from '../DispatchTimeSelector';
import { PRIORITY } from 'wix-ui-tpa/Button';
import { SectionNotification } from 'wix-ui-tpa/SectionNotification';
import { ReactComponent as Error } from 'wix-ui-tpa/dist/statics/assets/icons/Error.svg';
import AddressInformationSummary from './AddressInformationSummary';
import CheckoutFlowStepTitle from '../CheckoutFlowStepTitle';
import RestaurantTakeoutDetails from './RestaurantTakeoutDetails';
import AddressInformationDelivery from './AddressInformationDelivery';
import Text from '../../core-components/Text';
import { Trans, translate, useBi, useExperiments } from 'yoshi-flow-editor-runtime';
import { TranslationFunction } from 'i18next';
import {
  SetAddressInputErrorPayload,
  SetErrorVisibilityPayload,
  ToggleAllErrorsPayload,
} from '../../../../state/addressInformationForm/addressForm.actions.types';
import Button from '../Button';
import {
  Address,
  CurbsideInfo,
  DispatchInfo,
  DispatchType,
  DisplayableOrderItem,
  getBestLocationsForAddress,
  getCachedTranslationFunction,
  Restaurant,
  validateAddress,
  ValidateAddressReason,
  validateTakeout,
  VirtualDispatchType,
  getMinOrderPriceDetails,
  isMinimumPriceMet,
} from '@wix/restaurants-client-logic';
import { DeliveryFormField } from '../../../../state/addressInformationForm/addressForm.reducer';
import { getScrollOptions } from '../CheckoutFlow/CheckoutFlow';
import { getDisplayableAddressError, getErrorKey } from './AddressInformation.helper';
import { BusinessNotificationDetails } from '../../../../state/selectors/businessNotificationSelector';
import { Address as MembersAddress } from '@wix/ambassador-addresses-web/types';
import _ from 'lodash';
import SavedAddressView from './SavedAddressView';
import { OpenModalPayload, SaveAddressToServerPayload } from '../../../../state/session/session.actions.types';
import { Modals } from '../../../../core/constants';
import Spinner from '../Spinner';
import Checkbox from '../Checkbox';
import { convertToOloAddressMembersAddress } from '../../../../core/logic/addressLogic';
import CurbsidePickup from '../CurbsidePickup';
import type { PartialLocation } from '../../../../core/oloApi';
import { useSettings } from 'yoshi-flow-editor-runtime/tpa-settings/react';
import { componentSettings } from '../../componentSettings';
import DineInDetails from '../DineInDetails/index';
import { DineInInfo } from '@wix/restaurants-client-logic/dist/types/types/Restaurant';
import { getDispatchTypeFromVirtual } from '../../../../core/logic/dispatchLogic';

export interface AddressInformationProps {
  restaurant: Restaurant;
  dispatchTime: number;
  totalOrderPrice: number;
  dispatchType: VirtualDispatchType;
  done?: boolean;
  collapsed?: boolean;
  forceErrorVisibility?: boolean;
  index?: string;
  onSubmit: () => void;
  onEdit: () => void;
  setDispatchType: (dispatchType: SetDispatchTypePayload) => void;
  setDispatchTime: (payload: SetDispatchTimePayload) => void;
  address: Address;
  supportedDispatchTypes: Set<DispatchType>;
  formattedAddressWithComment: string;
  fieldsErrors: {
    addressInput: boolean;
    apt: boolean;
    timingOption: boolean;
  };
  toggleAllErrors: (payload: ToggleAllErrorsPayload) => void;
  setDeliveryAddressFromForm: () => void;
  t: TranslationFunction;
  isMobile: boolean;
  selectedAddressOption?: Address;
  setAddressInputError: (payload: SetAddressInputErrorPayload) => void;
  setFieldError: (payload: SetErrorVisibilityPayload) => void;
  setErrorVisibility: (payload: SetErrorVisibilityPayload) => void;
  addressInputError?: ValidateAddressReason;
  idealDeliveryArea?: DispatchInfo;
  errorOrderItem?: DisplayableOrderItem;
  saveStateToSessionStorage: () => void;
  timezone: string;
  errorsVisibility: Record<DeliveryFormField, boolean>;
  deliveryInfos: DispatchInfo[];
  describedby?: string;
  businessNotification?: BusinessNotificationDetails;
  locale: string;
  isRTL?: boolean;
  savedAddresses: MembersAddress[];
  openModal: (payload: OpenModalPayload) => void;
  isLoadingAddressesFromServer?: boolean;
  saveAddressToServer: (address: SaveAddressToServerPayload) => void;
  initialCurbside?: boolean;
  initialTableNumber?: string;
  curbsideInfo?: CurbsideInfo;
  initialCurbsideOutfitInfo?: string;
  setCurbside: (isCurbside: SetCurbsidePayload) => void;
  setCurbsideOutfitInfo: (curbsideOutfitInfo: SetCurbsideOutfitInfoPayload) => void;
  locations: PartialLocation[];
  isAddressSelectionModalOpen?: boolean;
  isUserLoggedIn?: boolean;
  setDineInTable: (payload: SetDineInTablePayload) => void;
  dineInInfo?: DineInInfo;
  isMAInstalled?: boolean;
}

const AddressInformation: React.FC<AddressInformationProps> = ({
  setDispatchType,
  dispatchType,
  index = '0',
  done,
  collapsed,
  onEdit,
  address,
  onSubmit,
  restaurant,
  totalOrderPrice,
  dispatchTime,
  supportedDispatchTypes,
  setDispatchTime,
  formattedAddressWithComment,
  toggleAllErrors,
  setDeliveryAddressFromForm,
  t,
  isMobile,
  selectedAddressOption,
  setAddressInputError,
  setFieldError,
  initialTableNumber,
  addressInputError,
  fieldsErrors,
  idealDeliveryArea,
  errorOrderItem,
  saveStateToSessionStorage,
  setErrorVisibility,
  timezone,
  errorsVisibility,
  deliveryInfos,
  describedby,
  businessNotification,
  locale,
  isRTL,
  savedAddresses,
  openModal,
  isLoadingAddressesFromServer,
  saveAddressToServer,
  initialCurbside,
  curbsideInfo,
  initialCurbsideOutfitInfo,
  setCurbside,
  setCurbsideOutfitInfo,
  locations,
  isAddressSelectionModalOpen,
  isUserLoggedIn,
  setDineInTable,
  dineInInfo,
  isMAInstalled,
}) => {
  const experiments = useExperiments();
  const location = useLocation();
  const settings = useSettings();
  const hasMembersAreaIntegration = settings.get(componentSettings.hasMembersAreaIntegration);
  const isMembersAddressEnabled =
    isMAInstalled &&
    isUserLoggedIn &&
    hasMembersAreaIntegration &&
    experiments.experiments.enabled('specs.restaurants.olo-client-members-area-addresses');
  const [isCurbsideToggledOn, onCurbsideToggle] = useState(initialCurbside);
  const [localCurbsideOutfitInfo, setLocalCurbsideOutfitInfo] = useState(initialCurbsideOutfitInfo);
  const [isDineInEmptyField, setIsDineInEmptyField] = useState(false);
  const [tableNumber, setTableNumber] = useState(initialTableNumber);

  const showCurbside = dispatchType === 'takeout' && curbsideInfo;
  const isMLEnabled = experiments.experiments.enabled('specs.restaurants.olo-client-ml');

  useEffect(() => {
    toggleAllErrors({ value: false });
  }, [location, toggleAllErrors]);

  const handleDispatchTimeSelectorChange = useCallback(
    ({ timingOption, selectedDateTime }) => {
      if (timingOption === 'asap') {
        setDispatchTime({ timestamp: undefined });
      } else {
        setDispatchTime({ timestamp: selectedDateTime });
      }
      setFieldError({ error: 'timingOption', value: false });
    },
    [setDispatchTime, setFieldError],
  );

  const [isTakeoutUnavailable, setIsTakeoutUnavailable] = useState(false);
  const [shouldSaveAddress, setShouldSaveAddress] = useState(false);
  const [curbsideOutfitInfoError, setCurbsideOutfitInfoError] = useState(false);

  const timeError = fieldsErrors.timingOption && (isTakeoutUnavailable || addressInputError?.type === 'unavailable');

  useEffect(() => {
    if (
      !isAddressSelectionModalOpen &&
      (errorsVisibility.addressInput || errorsVisibility.apt || errorsVisibility.timingOption)
    ) {
      scroller.scrollTo('address-input', getScrollOptions());
    }
  }, [errorsVisibility, isAddressSelectionModalOpen]);

  const biLogger = useBi();

  const setFieldErrorAndBi = ({ errorType }: { errorType: DeliveryFormField }) => {
    setFieldError({ error: errorType, value: true });
    biLogger.addressInformationContinueValidationError({
      comment: selectedAddressOption?.comment || '',
      dispatchTime,
      dispatchTimeOption: dispatchTime ? 'later' : 'asap',
      dispatchType,
      errorReason: errorType as string,
    });
  };

  const validateAddressInputAndRunSideEffects = (addressToValidate: Address | undefined) => {
    const validateAddressReason = validateAddress({
      address: addressToValidate,
      restaurant,
      dispatchTime,
      totalOrderPrice,
    });

    if (validateAddressReason?.type === 'unavailable') {
      setFieldErrorAndBi({ errorType: 'timingOption' });
    }

    setAddressInputError({ validateAddressReason });
    setErrorVisibility({ error: 'addressInput', value: true });
    biLogger.addressInformationContinueValidationError({
      comment: addressToValidate?.comment || '',
      dispatchTime,
      dispatchTimeOption: dispatchTime ? 'later' : 'asap',
      dispatchType,
      errorReason: validateAddressReason?.type as string,
    });

    if (isMLEnabled && validateAddressReason && validateAddressReason.type !== 'invalid-address' && addressToValidate) {
      const bestLocations = getBestLocationsForAddress(locations, addressToValidate, totalOrderPrice, dispatchTime);

      switch (bestLocations.status) {
        case 'ok':
          openModal({
            modal: Modals.CHANGE_LOCATION_MODAL,
            context: { locationId: bestLocations.locations[0].currentLocationId },
          });
          break;
        case 'error':
          // TODO: handle errors
          return;
        default:
          return;
      }
    }

    return validateAddressReason;
  };

  const hasSavedAddresses = !_.isEmpty(savedAddresses);

  const submitDispatch = () => {
    if (!hasSavedAddresses || !isMembersAddressEnabled) {
      setDeliveryAddressFromForm();

      if (shouldSaveAddress && selectedAddressOption) {
        saveAddressToServer({ address: convertToOloAddressMembersAddress(selectedAddressOption), setAsDefault: false });
      }
    }

    onSubmit();
    toggleAllErrors({ value: false });
    saveStateToSessionStorage();
    biLogger.addressInformationContinue({
      comment: selectedAddressOption?.comment || '',
      dispatchTime,
      dispatchTimeOption: dispatchTime ? 'later' : 'asap',
      dispatchType,
      curbsidePickupToggle: isCurbsideToggledOn,
      curbsideAdditionalInformationContent: localCurbsideOutfitInfo,
    });
  };
  const handleSubmitTakeout = () => {
    const { isValid: isTakeoutValid, reason: invalidTakeoutReason } = validateTakeout(restaurant, dispatchTime);

    if (!isTakeoutValid && invalidTakeoutReason === 'unavailable') {
      setIsTakeoutUnavailable(true);
      setFieldErrorAndBi({ errorType: 'timingOption' });
    }
    if (isTakeoutValid) {
      if (showCurbside) {
        setCurbside({ isCurbside: isCurbsideToggledOn });
        if (curbsideInfo?.additionalInformation && isCurbsideToggledOn) {
          if (!localCurbsideOutfitInfo) {
            setCurbsideOutfitInfoError(true);
            biLogger.addressInformationContinueValidationError({
              errorReason: 'Curbside Pickup',
            });
            return;
          }
          setCurbsideOutfitInfo({ curbsideOutfitInfo: localCurbsideOutfitInfo });
        }
      }
      submitDispatch();
    }
  };

  const handleSubmitDelivery = () => {
    const addressToValidate = hasSavedAddresses && isMembersAddressEnabled ? address : selectedAddressOption;
    const validateAddressReason = validateAddressInputAndRunSideEffects(addressToValidate);

    setErrorVisibility({ error: 'addressInput', value: true });

    const isAptValid =
      Boolean(addressToValidate?.apt) ||
      Boolean(addressToValidate?.addressLine2) ||
      !experiments.experiments.enabled('specs.restaurants.AptFieldIsRequiredInDelivery');

    toggleAllErrors({ value: true });

    if (!isAptValid) {
      setFieldErrorAndBi({ errorType: isMembersAddressEnabled ? 'addressLine2' : 'apt' });
    }

    if (!validateAddressReason && isAptValid) {
      submitDispatch();
    }
  };

  const handleSubmitDineIn = () => {
    if (!tableNumber) {
      setIsDineInEmptyField(true);
    } else {
      setDineInTable({ tableNumber });
      submitDispatch();
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    switch (dispatchType) {
      case 'takeout':
        handleSubmitTakeout();
        break;
      case 'delivery':
        handleSubmitDelivery();
        break;
      case 'dine-in':
        handleSubmitDineIn();
        break;
      default:
        return;
    }
  };

  const { displayableAmountLeft, minOrderPrice } = getMinOrderPriceDetails({
    totalOrderPrice,
    restaurant,
    dispatchType: getDispatchTypeFromVirtual(dispatchType),
  });

  const isMinPriceMet: boolean = isMinimumPriceMet({
    totalOrderPrice,
    restaurant,
    dispatchType: getDispatchTypeFromVirtual(dispatchType),
  });

  const setDispatchTypeAndBi = (dispatchTypePayload: SetDispatchTypePayload) => {
    // Evid: 830 - Checkout-> Dispatch update. (addToCartFailure it's a bad name)
    biLogger.addToCartFailure({
      lastState: dispatchTypePayload.dispatchType,
    });
    setDispatchType(dispatchTypePayload);
    const shouldSendMinOrderError = !isMinimumPriceMet({
      totalOrderPrice,
      restaurant,
      dispatchType: getDispatchTypeFromVirtual(dispatchTypePayload.dispatchType),
    });
    if (shouldSendMinOrderError) {
      biLogger.liveSiteMinimumOrderError({
        price: totalOrderPrice,
        minimumOrder: minOrderPrice,
        pageName: 'Checkout',
        dispatchType,
      });
    }
  };

  const idSuffix = Math.random();
  const addressInformationTitleId = `restaurants.address-information.title-${idSuffix}`;

  const renderDelivery = () => {
    return (
      <React.Fragment>
        {isLoadingAddressesFromServer && (
          <div className={styles.spinner}>
            <Spinner data-hook={dataHooks.addressInformationSpinner} />
          </div>
        )}

        {!isLoadingAddressesFromServer && (!hasSavedAddresses || !isMembersAddressEnabled) && (
          <AddressInformationDelivery
            restaurant={restaurant}
            dispatchTime={dispatchTime}
            totalOrderPrice={totalOrderPrice}
            onAddressInputBlur={validateAddressInputAndRunSideEffects}
            onAddressInputSelect={validateAddressInputAndRunSideEffects}
            showAddressLine2={isMembersAddressEnabled}
          />
        )}

        {!isLoadingAddressesFromServer && !hasSavedAddresses && isMembersAddressEnabled && (
          <Checkbox
            label={t('checkout_main_deliverymethod.saveaddress.text')}
            onChange={() => {
              const newValue = !shouldSaveAddress;
              biLogger.checkboxSaveAddressForFutureOrders({ value: newValue ? 'on' : 'off' });
              setShouldSaveAddress(newValue);
            }}
            checked={shouldSaveAddress}
            data-hook={dataHooks.addressInformationSaveAddressCheckbox}
            name={dataHooks.addressInformationSaveAddressCheckbox}
            className={styles.checkbox}
          />
        )}

        {!isLoadingAddressesFromServer && hasSavedAddresses && isMembersAddressEnabled && (
          <SavedAddressView
            address={address}
            onChange={() => {
              biLogger.openAddressSelectionModal({});
              openModal({ modal: Modals.ADDRESS_SELECTION });
            }}
            error={getDisplayableAddressError({
              address,
              restaurant,
              dispatchTime,
              t,
              totalOrderPrice,
              isAptRequired: experiments.experiments.enabled('specs.restaurants.AptFieldIsRequiredInDelivery'),
            })}
          />
        )}
      </React.Fragment>
    );
  };

  const renderTakeout = () => {
    return (
      <RestaurantTakeoutDetails
        address={restaurant.address}
        formattedAddressWithComment={formattedAddressWithComment}
        onLocationChange={(id: string) => {
          openModal({
            modal: Modals.CHANGE_LOCATION_MODAL,
            context: { locationId: id },
          });
        }}
        className={false}
      />
    );
  };

  const onChangeTableNumber = (value: string) => {
    /* Validate that the tableNumber contains only letters and digits */
    const validValue = value.match(/[\p{L}|\p{N}|\s]/gu)?.join('');

    if (validValue !== tableNumber) {
      setIsDineInEmptyField(false);
      setTableNumber(value);
    }
  };

  const renderDineIn = () => (
    <DineInDetails
      tableNumber={tableNumber}
      onChangeTableNumber={onChangeTableNumber}
      shouldShowError={isDineInEmptyField}
    />
  );

  return (
    <div
      data-hook={dataHooks.addressInformation}
      className={styles.wrapper}
      aria-labelledby={addressInformationTitleId}
      aria-describedby={describedby}
    >
      <CheckoutFlowStepTitle
        text={t('checkout_main_delivery_method')}
        done={done}
        collapsed={collapsed}
        index={index}
        onEdit={onEdit}
        editButtonDataHook={dataHooks.checkoutSummaryLineEditAddress}
        titleId={addressInformationTitleId}
      />

      {!done && !collapsed && supportedDispatchTypes.size > 1 && (
        <DispatchTypeSelector
          className={styles.selector}
          dispatchType={dispatchType}
          setDispatchType={setDispatchTypeAndBi}
          supportedDispatchTypes={supportedDispatchTypes}
          isMobile={isMobile}
        />
      )}

      {!done && !collapsed && (
        <form onSubmit={handleSubmit} data-hook={dataHooks.addressInformationForm}>
          {dispatchType === 'delivery' && renderDelivery()}
          {dispatchType === 'takeout' && renderTakeout()}
          {dispatchType === 'dine-in' && renderDineIn()}

          {dispatchType !== 'dine-in' && (
            <DispatchTimeSelector
              businessNotification={businessNotification}
              idealDeliveryArea={idealDeliveryArea}
              isMobile={isMobile}
              restaurant={restaurant}
              dispatchType={dispatchType}
              dispatchTime={dispatchTime}
              timingOption={dispatchTime ? 'later' : 'asap'}
              onChange={handleDispatchTimeSelectorChange}
              t={getCachedTranslationFunction(t)}
              error={timeError ? t('checkout_main_delivery_time_errormessage') : undefined}
              isRTL={isRTL}
            />
          )}
          {showCurbside && (
            <CurbsidePickup
              setCurbsideOutfitInfo={setLocalCurbsideOutfitInfo}
              curbsideOutfitInfo={localCurbsideOutfitInfo}
              isCurbsideOn={isCurbsideToggledOn}
              onCurbsideToggle={onCurbsideToggle}
              curbsideInfo={curbsideInfo}
              outfitInfoError={curbsideOutfitInfoError}
            />
          )}

          {errorOrderItem && getErrorKey(errorOrderItem) && (
            <SectionNotification type="error" className={styles.error} data-hook={dataHooks.addressInformationError}>
              <SectionNotification.Icon icon={<Error />} />
              <SectionNotification.Text>
                <Trans
                  t={t}
                  i18nKey={getErrorKey(errorOrderItem)}
                  components={[
                    <Link data-hook={dataHooks.addressInformationErrorLink} to="/cart">
                      placeholder
                    </Link>,
                  ]}
                />
              </SectionNotification.Text>
            </SectionNotification>
          )}

          {!isMinPriceMet && dispatchType === 'takeout' && (
            <SectionNotification
              type="error"
              className={styles.error}
              data-hook={dataHooks.addressInformationMinOrderPriceErrorBanner}
            >
              <SectionNotification.Icon icon={<Error />} />
              <SectionNotification.Text>
                <Trans
                  t={t}
                  i18nKey={'checkout_main_order_minprice_errormessage_with_link'}
                  components={[
                    displayableAmountLeft,
                    <Link data-hook={dataHooks.addressInformationMinOrderPriceErrorBannerLink} to="/">
                      placeholder
                    </Link>,
                  ]}
                />
              </SectionNotification.Text>
            </SectionNotification>
          )}

          <Button
            upgrade
            fullWidth
            priority={PRIORITY.primary}
            className={styles.button}
            data-hook={dataHooks.addressInformationContinue}
            type="submit"
            disabled={!!errorOrderItem || !isMinPriceMet}
          >
            <Text typography="p2-m-colorless">{t('checkout_main_button_continue')}</Text>
          </Button>
        </form>
      )}

      {done && (
        <AddressInformationSummary
          dineInInfo={dineInInfo}
          address={dispatchType === 'delivery' ? address : restaurant.address}
          dispatchType={dispatchType}
          dispatchTime={dispatchTime}
          timezone={timezone}
          locale={locale}
          tableNumber={initialTableNumber}
          deliveryInfos={deliveryInfos}
          idealDeliveryArea={idealDeliveryArea}
          isCurbsideToggledOn={isCurbsideToggledOn}
        />
      )}
    </div>
  );
};

AddressInformation.displayName = 'AddressInformation';

export default translate()(AddressInformation);
