import CartModal from './CartModal';
import { AppState } from '../../../../state/createStore';
import { connect } from 'react-redux';
import { cartSummarySelector } from '../../../../state/selectors/cartSummarySelector';
import { allBusinessNotificationsSelectorByDispatchTime } from '../../../../state/selectors/businessNotificationSelector';
import { bindActionCreators, Dispatch } from 'redux';
import { openModal } from '../../../../state/session/session.actions';
import { isMinimumPriceMet, getMinOrderPriceDetails } from '@wix/restaurants-client-logic';
import { clickGoToCheckout } from '../../../../state/checkout/checkout.actions';

function mapStateToProps(state: AppState, ownProps: any) {
  const { displayableOrderItems, totalOrderPrice } = cartSummarySelector(state);
  const { displayableAmountLeft, minOrderPrice } = getMinOrderPriceDetails({
    totalOrderPrice,
    restaurant: state.session.restaurant,
    dispatchType: state.checkout.dispatch.type,
  });
  return {
    itemCount: state.cart.orderItems.length,
    displayableOrderItems,
    businessNotifications: allBusinessNotificationsSelectorByDispatchTime(state).map((bnd) => bnd.notification),
    isMobile: state.platformParams.isMobile,
    isMinimumPriceMet: isMinimumPriceMet({
      totalOrderPrice,
      restaurant: state.session.restaurant,
      dispatchType: state.checkout.dispatch.type,
    }),
    displayableAmountLeft,
    minOrderPrice,
    dispatchType: state.checkout.dispatch.type,
    totalOrderPrice,
    ...ownProps,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      openModal,
      clickGoToCheckout,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CartModal);
