import { extractLocalizedString, LocalizedString } from '@wix/restaurants-client-logic';
import React from 'react';
import dataHooks from '../../data-hooks';

interface Props {
  name?: LocalizedString;
  locale: string;
  address: string;
}
const LocationHeader: React.FC<Props> = ({ address, name, locale }) => {
  return (
    <div data-hook={dataHooks.locationHeader}>
      {name && <h1>{extractLocalizedString(name, locale)}</h1>}
      <p>{address}</p>
    </div>
  );
};
export default LocationHeader;
